import * as React from "react";
import { useState } from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import onlineLesson from "@images/proyectos/online-lesson.png";
import design from "@images/proyectos/design.png";
import idea from "@images/proyectos/idea.png";
import systems from "@images/proyectos/systems.png";
import adorno from "@images/adorno/adorno2.png"

const IndexPage = () => {

  const [select, setselect] = useState(-1)

  return (
    <TemplatePage1
      adorno={<Adorno />}
      title={"Proyectos Futuros"}
      content={(
        <Wrapper >
          <CardContainer>
            <Card>
              <Hexagon bgc={"white"}>
                <img src={design} alt={design} />
              </Hexagon>
              <TextZone>
                <h1 className={select === 1 ? "text-2xl font-bold" : "text-3xl"} onClick={() => { select === 1 ? setselect(-1) : setselect(1) }} >
                  Rediseño de la Atención Primaria en Salud
                </h1>

                <div className={select === 1 ? "text-2xl show transition-all text-justify" : "hidden"}>
                  <p>
                    Evaluaciones distritales a los centros de atención primaria de la salud, los proveedores y la comunidad, con el fin de tener un análisis de viabilidad para diseñar intervenciones destinadas a modificar el flujo de pacientes a través del primer nivel de atención, para mejorar la atención de los servicios esenciales con especial énfasis en la resiliencia para emergencias de salud pública, atención materno - infantil y afecciones crónicas.
                  </p>
                </div>

              </TextZone>
            </Card>
            <Card left>
              <Hexagon bgc={"white"}>
                <img src={systems} alt={systems} />
              </Hexagon>
              <TextZone>
                <h1 className={select === 2 ? "text-2xl font-bold" : "text-3xl"} onClick={() => { select === 2 ? setselect(-1) : setselect(2) }} >Desarrollo de soluciones para microsistemas</h1>
                <div className={select === 2 ? "text-2xl show transition-all text-justify" : "hidden"} >
                  <p>Basados en marcos científicos de implementación, planeamos desarrollar estrategias para aumentar la adopción de intervenciones basadas en evidencia y rentables que puedan funcionar en diferentes entornos y países.
                  </p>
                </div>

              </TextZone>
            </Card>
            <Card>
              <Hexagon bgc={"white"}>
                <img src={onlineLesson} alt={onlineLesson} />
              </Hexagon>
              <TextZone>
                <h1 className={select === 3 ? "text-2xl font-bold" : "text-3xl"} onClick={() => { select === 3 ? setselect(-1) : setselect(3) }} >Creación de una estructura informativa digital para el aprendizaje compartido</h1>

                <div className={select === 3 ? "text-2xl show transition-all text-justify" : "hidden"}>
                  <p>Repositorio de datos para comparaciones entre países, así como un repositorio de soluciones que se difundirá más allá de las publicaciones de revisión por pares para acelerar la adopción</p>
                </div>

              </TextZone>
            </Card>
            <Card left>
              <Hexagon bgc={"white"}>
                <img src={idea} alt={idea} />
              </Hexagon>
              <TextZone>
                <h1 className={select === 4 ? "text-2xl font-bold" : "text-3xl"} onClick={() => { select === 4 ? setselect(-1) : setselect(4) }} >Otros proyectos</h1>
                <div className={select === 4 ? "show text-2xl transition-all" : "hidden"} >
                  <p>Serán identificados a través de la priorización de miembros de redes y serán liderados por postdocs en los Centros Core y afiliados.
                  </p>
                </div>

              </TextZone>
            </Card>
          </CardContainer>
        </Wrapper >
      )}
    />
  )
};

export default IndexPage



const Hexagon = styled.div`
position: relative;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-color: ${props => props.bgc ? props.bgc : ""};
background-size: cover;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
text-align: center;

@media only screen and (min-width:768px){
  height: 150px;
max-height:150px;
min-height:150px;
width: 150px;
max-width:150px;
min-width:150px;

}
height: 90px;
max-height:90px;
min-height:90px;
width: 90px;
max-width:90px;
min-width:90px;
img{
  height:70%;
}
`

const Wrapper = styled.div`
padding-top: 1rem;
`;

const CardContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`

const Card = styled.div`
background-color:var(--tema4);
border-radius: 20px;
display: flex;
flex-direction: ${props => props.left ? "row-reverse" : "row"};
justify-content: flex-start;
align-items: center;
padding: 2rem 1rem 2rem 1rem;
margin: 1rem 0 1rem 0;
width: 100%;



`;

const TextZone = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding:15px;
color: white;
transition:all 1s;
will-change: contents;


h1{
  margin: 0.2rem 0 0.5rem 0;
  text-align: center;
  cursor: pointer;
}

.ownsmall h1{
  font-size:1rem;
}
.ownbig h1{
  font-size:2rem;
}


div{
  .ownshow{
    display: block;
  }
  .ownhidden{
    display: none;
  }
}
`;




const Adorno = styled.div`
position: absolute;
bottom: 0px;
right: 1100px;
height:100%;
@media only screen and (min-width:768px){
  bottom: 0px;
  left:-400px;
right: auto;
height:100%;
  width: 1300px;
  background-position-x:-250px;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  bottom: 0px;


height:100%;
  width: 1300px;
  background-position-x:-250px;
  background-image: url(${adorno});
background-size: contain;
background-repeat: no-repeat;
}
`;






















